import React from "react"
import styled from "styled-components"
import theme from "../general/theme"
import Layout from "../components/Layout"
import { StaticQuery, graphql } from "gatsby"
import Box from "../elements/Box"
import {
  // Canon,
  Trafalgar,
  // Paragon,
  GreatPrimer,
  // DoublePica,
  BodyCopy,
  SmallCap,
  // SmallPrint,
  // Code,
} from '../typography';
import getPerson from "../utilities/getPerson"
import getDomainName from "../utilities/getDomainName"
const BlockContent = require('@sanity/block-content-to-react')
const { white, gray7, gray8 } = theme.colours;
const { primary } = theme.linkStyle;

const ProjectSection = styled.section`
  .desc {
    text-align: justify;
  }
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-rows: auto auto auto auto auto;
  grid-template-columns: 1fr 1fr 1fr; 
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  grid-template-areas: 
    "name name name"
    "projectLeaders description description"
    "networkInvestigators description description"
    "affiliatedResearchers description description"
    "website description description";
`;


const serializers = {
  types: {
    description: props => (
    <BodyCopy className="desc">{props.children}</BodyCopy>
    )
  }
}

const ResearchProjectsPage = ({ data }) => {
  const projects = data.allSanityResearchProject.edges;
  // console.log(projects);

  const coreProjects = projects.filter(i => i.node._rawResearchProjectCategory[0].name === 'Core' ? i : false)
  const otherProjects = projects.filter(i => i.node._rawResearchProjectCategory[0].name === 'Other' ? i : false)
  console.log(coreProjects);
  console.log(otherProjects);

  function projectCard (project) {
    let id = project.node.id || '';
    let name = project.node.name || '';
    let description = project.node._rawDescription || null;
    let projectLeaders = project.node._rawProjectLeaders || null;
    let networkInvestigators = project.node._rawNetworkInvestigators || null;
    let affiliatedResearchers = project.node._rawAffiliatedResearchers || null;
    let website = project.node.url || '';

    // console.log(project);

      return (
        <ProjectSection key={id}>
          <Box p="4rem" background={white} borderRadius="8px" mb="4rem">
            <GridContainer>
              <>
                <Box gridArea="name"><Trafalgar color={gray8}>{name}</Trafalgar></Box>
                <Box gridArea="projectLeaders">
                  <>
                    { projectLeaders && (<SmallCap color={gray8} mb="0.6rem">PROJECT LEADERS</SmallCap>) }
                    { projectLeaders && projectLeaders.map(getPerson) }  
                  </>
                </Box>
                <Box gridArea="networkInvestigators">
                  <>
                    { networkInvestigators && (<SmallCap color={gray8}mb="0.6rem">NETWORK INVESTIGATORS</SmallCap>) }
                    { networkInvestigators && networkInvestigators.map(getPerson) }
                  </>
                </Box>
                <Box gridArea="affiliatedResearchers">
                  <>
                    { affiliatedResearchers && (<SmallCap color={gray8}mb="0.6rem">AFFILIATED RESEARCHERS</SmallCap>) }
                    { affiliatedResearchers && affiliatedResearchers.map(getPerson) }
                  </>
                </Box>
                <Box gridArea="website">
                  <>
                    { website && (<SmallCap color={gray8}mb="0.6rem">WEBSITE</SmallCap>) }
                    { website &&
                      (<a href={website} target="_blank" rel="noopener noreferrer" style={primary}>
                        <GreatPrimer color={gray7}>{getDomainName(website)}</GreatPrimer>
                      </a>) }
                  </>
                </Box>
                <Box gridArea="description" maxWidth="670px" color={gray7}>
                  { description && (<SmallCap color={gray8}mb="0.6rem">DESCRIPTION</SmallCap>) }
                  <BlockContent blocks={description && description} 
                    serializers={{types: {block: serializers.types.description}}} />
                </Box>
              </>
            </GridContainer>
          </Box>
        </ProjectSection>
      )
    }

  return (
    <Layout>
      { coreProjects.map(projectCard) }
      { otherProjects.map(projectCard) }
    </Layout>
  )
}


export default props => (
  <StaticQuery
    query={graphql`
      query ResearchProjects {
        allSanityResearchProject(sort: {fields: position}) {
          edges {
            node {
              _rawDescription(resolveReferences: {maxDepth: 10})
              _rawProjectLeaders(resolveReferences: {maxDepth: 10})
              _rawAffiliatedResearchers(resolveReferences: {maxDepth: 10})
              url
              _rawNetworkInvestigators(resolveReferences: {maxDepth: 10})
              name
              _rawResearchProjectCategory(resolveReferences: {maxDepth: 10})
            }
          }
        }
      }
    `}
    render={data => <ResearchProjectsPage data={data} {...props} />}
  />
)

