import React from "react"
import theme from "../general/theme"
import { GreatPrimer } from '../typography';
const { gray7 } = theme.colours;
const { primary } = theme.linkStyle;


function getPerson(i) {
  let id = i.id || '';
  let name = i.name || '';

  return (
    <GreatPrimer key={id} color={gray7} mb="0.3rem">
      <a href={`/contact#${id}`} style={primary}>{name}</a>
    </GreatPrimer>
  )
}

export default getPerson;